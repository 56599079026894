import gql from 'graphql-tag';

export default gql`
  query CardStackQuery($id: [QueryArgument]) {
    cardStack: entry(type:["cards", "cardSet"], id: $id) {
      id
      slug
      title

      children(section: "cards") {
        id
        slug
        title

        ... on cards_cards_Entry {
          cardImage {
            id
            title
            url
          },
          cardThumbnail {
            id
            title
            url
          }
        }
      }
    }
  }
`;
