import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { kebabCase } from 'lodash-es';
import MediaQuery from 'react-responsive';
import TechnologyIcon from '../../TechnologyIcon';
import arrowDown from '../../../../icons/arrow-down.svg';
import arrowUp from '../../../../icons/arrow-up.svg';
import './CepProjectDetails.css';
import ProjectDetail from './ProjectDetail';
import arrowLeft from '../../../../icons/arrow-left.svg';
import arrowRight from '../../../../icons/arrow-right.svg';

const CepProjectDetails = ({
  projects,
  activeProject,
  selectProject,
  setActiveSnapshot,
  technologyPages,
}) => {
  const wrapperRef = useRef(null);
  const projectsRef = useRef([]);
  const scrollInterval = useRef(null);
  const scrollAmount = 10;
  const scrollSpeed = 10;
  const scrollOptions = {
    top: scrollAmount,
    behavior: 'instant'
  };

  useEffect(() => {
    if (activeProject) {
      // TODO: Marker style changes triggered here?
      projectsRef.current[activeProject].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [activeProject]);

  const startScroll = (dir, horizontal = true) => {
    if(horizontal) {
      scrollOptions.top = 0
      scrollOptions.left = dir === 'right' ? scrollAmount : scrollAmount * -1;
    } else {
      scrollOptions.left = 0
      scrollOptions.top = dir === 'down' ? scrollAmount : scrollAmount * -1;
    }

    if (scrollInterval.current) return;

    scrollInterval.current = setInterval(() => {
      if(wrapperRef.current) {
        wrapperRef.current.scrollBy(scrollOptions);
      }
    }, scrollSpeed);
  }

  const stopScroll = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }
  }

  return(
    <div className="project-details-container center-map-ui">
      <div className="details-wrapper" ref={wrapperRef}>
        <ul className="details-list">
          {projects.map((project) => {
            const id = project["Id"].toString();
            const technologyTitles = project["Technology Titles"].length ? project["Technology Titles"] : [];

            return (
              <li
                className={classNames(
                  'project',
                  id === activeProject ? 'active' : '',
                )}
                key={id}
                ref={el => projectsRef.current[id] = el}
              >
                <button
                  className="project-expand"
                  aria-expanded={activeProject !== id}
                  aria-controls={`project-${id}`}
                  onClick={() => selectProject(activeProject !== id ? id : null)}
                >
                  <div className="project-icon">
                    <TechnologyIcon slug={technologyTitles.length ? kebabCase(technologyTitles[0]) : ''} />
                  </div>
                  <h3 className="project-name" id={`detail-label-${id}`}>
                    {project["Project Name"]}
                  </h3>
                </button>
                <div
                  className="project-details"
                  id={`project-${id}`}
                  aria-labelledby={`detail-label-${id}`}
                  role="region"
                >
                  <ProjectDetail
                    project={project}
                    technologyPages={technologyPages}
                    setActiveSnapshot={setActiveSnapshot}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <MediaQuery maxWidth={991}>
        <div className="project-details-scrollers--mobile">
          <button
            className="project-details-scroller"
            aria-label="scroll projects left"
            onMouseDown={(e) => startScroll('left', true)}
            onMouseUp={() => stopScroll()}
            onMouseLeave={() => stopScroll()}
          >
            <ReactSVG src={arrowLeft} />
          </button>
          <button
            className="project-details-scroller"
            aria-label="scroll projects right"
            onMouseDown={() => startScroll('right', true)}
            onMouseUp={() => stopScroll()}
            onMouseLeave={() => stopScroll()}
          >
            <ReactSVG src={arrowRight} />
          </button>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <div className="project-details-scrollers--desktop">
          <button
            className="project-details-scroller"
            aria-label="scroll projects up"
            onMouseDown={(e) => startScroll('down', false)}
            onMouseUp={() => stopScroll()}
            onMouseLeave={() => stopScroll()}
          >
            <ReactSVG src={arrowDown} />
          </button>
          <button
            className="project-details-scroller"
            aria-label="scroll projects down"
            onMouseDown={() => startScroll('up', false)}
            onMouseUp={() => stopScroll()}
            onMouseLeave={() => stopScroll()}
          >
            <ReactSVG src={arrowUp} />
          </button>
        </div>
      </MediaQuery>
    </div>
  );
};

export default CepProjectDetails;
