import React, { useEffect, useState } from 'react';
import "./ProjectDetail.css";

function parseInvestment(amount) {
  const num = amount ? parseInt(amount.replace('$', '').replaceAll(',', ''), 10) : null;

  return num;
}

const ProjectDetail = ({project, technologyPages, setActiveSnapshot, modal = false}) => {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'long',
    roundingPriority: 'morePrecision'
  });
  const investment = project["Investment Amount"];
  const techIds = project["Technology IDs"] ? project["Technology IDs"].map((id) => parseInt(id.replace(',', ''), 10)) : [];
  const technologyTitles = project["Technology Titles"].length ? project["Technology Titles"] : [];
  const sources = [];

  if (project["Announcement Text"] && project["Announcement"]) {
    sources.push({
      text: project["Announcement Text"],
      url: project["Announcement"],
    });
  }

  if (project["Additional Resource Link Text"] && project["Additional Resource Link"]) {
    sources.push({
      text: project["Additional Resource Link Text"],
      url: project["Additional Resource Link"],
    });
  }

  return (
    <dl>
      <div className="detail">
        <dt>Technology Type</dt>
        <dd>
          {techIds.map((techId, i) => {
            let typeEl = '';

            if (technologyPages[techId]) {
              typeEl = <span key={i}><a href={technologyPages[techId]}>{technologyTitles[i]}{i < techIds.length - 1 ? ', ' : ''}</a></span>
            } else {
              typeEl = <span key={i}>{technologyTitles[i]}{i < techIds.length - 1 ? ', ' : ''}</span>;
            }

            return (typeEl);
          })}
        </dd>
      </div>
      <div className="detail">
        <dt>Jobs Created</dt>
        <dd>{project["Job Creation Projection"]}</dd>
      </div>
      <div className="detail">
        <dt>Congressional District</dt>
        <dd>{project["Congressional District"]}</dd>
      </div>
      <div className="detail">
        <dt>Investment Amount</dt>
        <dd>{(parseInvestment(investment) > 999999) ? currency.format(parseInvestment(project["Investment Amount"])) : investment}</dd>
      </div>
      <div className="detail">
        <dt>State</dt>
        <dd>{project["State"]}</dd>
      </div>
      <div className="detail">
        <dt>County</dt>
        <dd>{project["County"]}</dd>
      </div>
      <div className="detail">
        <dt>Zip</dt>
        <dd>{project["Zipcode"]}</dd>
      </div>
      {project["Snapshot Title"] && !modal && (
        <div className="detail snapshot">
          <dt>Project Snapshot</dt>
          <dd>
            <button aria-label="open project snapshot" onClick={() => {setActiveSnapshot(project["Snapshot ID"])}}>
              {project["Snapshot Title"]}
            </button>
          </dd>
        </div>
      )}
      <div className="detail">
        <dt>Source</dt>
        <dd>
          {sources.map((source, i) => {
              return <span key={i}><a href={source.url} key={i}>{source.text}</a>{i < sources.length - 1 ? ',     ' : ''}</span>
          })}
        </dd>
      </div>
    </dl>
  )
}

export default ProjectDetail;
