import gql from 'graphql-tag';
import { RelatedMapProducts} from '../../fragments/RelatedProducts';

const MapPageQuery = gql`
query mapQuery {
    mapContent: entry(section: "map") {
      id
      enabled
      slug
      title
      ... on map_map_Entry {
        heroOverline
        heroHeading
        heroBody
        heroImage {
          id
          title
          url
        }
        secondaryImages {
          url
          title
          id
        }
        mapOverline
        mapHeading
        mapBody
        caption
        projectSnapshotsHeading
        featuredProjectSnapshots {
          ... on projectSnapshots_default_Entry {
            id
            slug
            title
            city
            state
            body
            energyTechnology {
              ... on energyTechnology_default_Entry {
                id
                slug
                title
                technologyId
              }
            }
          }
        }
        relatedContentHeading
        ...RelatedMapProducts
        methodologyHeading
        methodologyBody
      }
    }
    recentSnapshots: entries(section: "projectSnapshots", orderBy: "dateCreated DESC") {
      ... on projectSnapshots_default_Entry {
        id
        slug
        title
        city
        state
        body
        energyTechnology {
          ... on energyTechnology_default_Entry {
            id
            slug
            title
            technologyId
          }
        }
      }
    }
    energyTechnology: entries(section: "energyTechnology") {
      ... on energyTechnology_default_Entry {
        id
        slug
        title
        technologyId
        technologyPage {
          uri
        }
      }
    }
  }

  ${RelatedMapProducts}
`;

export default MapPageQuery;
