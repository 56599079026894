import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash-es';
import useScreenSize from '../../../utils/useScreenSize';
import Modal from '../../Modal';
import Content from '../../Content';
import CloseIcon from '../../../icons/Close-X.svg'
import TechnologyIcon from '../TechnologyIcon';
import './MapPageProjectModal.css';
import ProjectDetail from '../CEPMap/CepProjectDetails/ProjectDetail';

const ProjectModal = ({snapshot, project, technologyPages, closeHandler}) => {
  const screenSize = useScreenSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (isMobile && screenSize.width > 991) {
      setIsMobile(false);
    } else if (!isMobile && screenSize.width <= 991) {
      setIsMobile(true);
    }
  }, [screenSize]);

  if (project && (snapshot || isMobile)) {
    return (
      <Modal className="ProjectModal" label={project.title} onClickOutside={closeHandler}>
        <div className="modal-body">
          <button
            className="close-button c-pointer"
            aria-label={`Close ${project.title} details`}
            onClick={closeHandler}
          >
            <img src={CloseIcon} alt="" aria-hidden="true" />
          </button>
          <div className="content">
            {project && (
              <div className="project">
                <div className="project-heading">
                  {project["Technology Titles"].map((tech) => (
                    <TechnologyIcon key={kebabCase(tech)} slug={kebabCase(tech)} />
                  ))}
                  <h2 className="project-title">
                    {project["Project Name"]}
                  </h2>
                </div>
                <ProjectDetail
                  project={project}
                  technologyPages={technologyPages}
                  modal
                />
              </div>
            )}
            {snapshot && (
              <div className="snapshot">
                <p className="snapshot-label">
                  Snapshot
                </p>
                <h3 className="text-paragraph-large">
                  {snapshot.title}
                </h3>
                <p className="location text-caption">
                  {snapshot.city}, {snapshot.state}
                </p>
                <div className="snapshot-content">
                  <Content content={snapshot.body} />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }

  return null;
};

ProjectModal.propTypes = {
  snapshot: PropTypes.object,
  onClickOutside: PropTypes.func,
};

export default ProjectModal;
