import React, { useEffect, useState } from 'react';
import './StateFilter.css';

// TODO: Consider creating a dynamic list of states with projects
import stateAbbreviationData  from '../state-abbreviations.json';

const CepUsStateFilter = ({ onFilter, states, selectedUsState, setSelectedUsState, clearFilters }) => {
  const [options, setOptions] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedUsState(value); // Update the state in the parent component
    onFilter(value); // Call the onFilter function passed from the parent component
  };

  useEffect(() => {
    setOptions(Object.entries(stateAbbreviationData).filter((state) => states.includes(state[1])));
  }, [JSON.stringify(states)]);

  return(
    <div className="state-filter center-map-ui">
      <label className="state-filter-label">Filter by State</label>
      <div className="state-filter-controls">
        <select
          className="form-select"
          value={selectedUsState} // Controlled component: value is managed by state
          onChange={handleChange}
        >
          <option value="">Select State</option>
          {options.map(([state, abbr], i) => (
            <option key={i} value={abbr}>
              {state}
            </option>
          ))}
        </select>
        <button className="clear-filters text-caption" onClick={clearFilters}>Clear filters</button>
      </div>
    </div>
  );
};

export default CepUsStateFilter;
