import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import uuidV1 from 'uuid/v1';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import { TwitterShareButton } from 'react-share';
import { withRouter } from 'react-router';

import CardStack from '../../containers/CardStack';
import client from '../../apollo';
import sanitize from '../../utils/sanitize';
import SocialLink from '../SocialLink';
import urlIsExternal from '../../utils/urlIsExternal';

import './Content.css';
import PdfDeck from '../PdfDeck';

class Content extends Component {
  constructor() {
    super();

    this.id = uuidV1();
    this.articlePage = null;
    this.state = {
      eventListeners: [],
    };
  }

  componentDidMount() {
    this.articlePage = document.getElementById(this.id);

    this.attachExternalLinkListeners();
    this.handleImages();
    this.attachShareToPullquotes();
    this.adjustIFrameWidth();
    this.populateCardStacks();
    this.wrapTablesForHorizontalScrolling();

    if (
      typeof window !== 'undefined' &&
      window.twttr &&
      window.twttr.widgets &&
      typeof window.twttr.widgets.load === 'function'
    ) {
      window.twttr.widgets.load();
    }
  }

  componentWillUnmount() {
    this.state.eventListeners.map((link) => {
      return link.removeEventListener('click', this.onExternalLinkClick);
    });
  }

  adjustIFrameWidth() {
    if (this.articlePage) {
      const iframes = this.articlePage.getElementsByTagName('iframe');

      if (iframes.length) {
        for (let i = 0; i < iframes.length; i++) {
          const iframe = iframes[i];
          const width = iframe.getAttribute('width');
          const height = iframe.getAttribute('height');
          const iframeRatio = `${width}/${height}`;

          // create wrapper container
          const wrapper = document.createElement('div');
          wrapper.classList.add('iframe-wrapper');

          if (iframe.src.includes('contain=true') > 0) {
            wrapper.classList.add('contain');
          }

          if (iframe.src.indexOf('public.tableau') > 0) {
            wrapper.classList.add('tableau');
          }

          wrapper.setAttribute('style', `--ratio: ${iframeRatio};`);

          // insert wrapper before el in the DOM tree
          iframe.parentNode.insertBefore(wrapper, iframe);

          // move el into wrapper
          wrapper.appendChild(iframe);
        }
      }
    }
  }

  attachExternalLinkListeners() {
    const eventListeners = [];

    if (this.articlePage) {
      const articleLinks = this.articlePage.getElementsByTagName('a');

      for (let i = 0; i < articleLinks.length; i++) {
        let link = articleLinks[i];
        if (urlIsExternal(link.getAttribute('href'))) {
          link.addEventListener('click', this.onExternalLinkClick);
          eventListeners.push(link);
        }
      }
      this.setState({ eventListeners });
    }

    const { hash } = this.props.location;
    const element = document.getElementById(hash.replace('#', ''));

    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo(0, middle);
    }
  }

  handleImages() {
    if (!this.articlePage) {
      return;
    }

    const images = this.articlePage.getElementsByTagName('img');

    const filteredImages = Array.from(images).filter((item) => {
      return (
        item.parentNode.className.indexOf('entry-image') === -1 &&
        !('flickrEmbed' in item.parentNode.dataset)
      );
    });

    // This wraps unwrapped images in an entry-image container so they get picked up
    // by the twitter sharing
    filteredImages.forEach((image) => {
      const isPdf = image.src.split('.').pop() === 'pdf';
      let node = document.createElement('div');
      node.className = isPdf ? 'embedded-pdf' : 'entry-image';

      if (isPdf) {
        node.dataset.src = image.src;
        node.dataset.title = `${image.alt} ${image.alt.length ?  ' | ' : ''}Thirdway`;
      }
      node.appendChild(image.cloneNode(true));

      image.parentNode.replaceChild(node, image);
    });

    if (filteredImages.length) {
      this.attachShareToImages();
      this.embedPdfs();
    }
  }

  embedPdfs() {
    const pdfWrappers = this.articlePage.getElementsByClassName('embedded-pdf');

    Array.from(pdfWrappers).forEach((wrapper) => {
      ReactDOM.render(
        <PdfDeck url={wrapper.dataset.src} title={wrapper.dataset.title} />,
        wrapper
      )
    });
  }

  attachShareToImages() {
    const entryImages = this.articlePage.getElementsByClassName('entry-image');

    // Loop through each entry-image and add the sharing functionality
    for (let i = 0; i < entryImages.length; i++) {
      let image = entryImages[i].getElementsByTagName('img');

      // If the node doesn't have an image, continue to the next
      if (!image[0] || image[0].parentElement !== entryImages[i]) {
        continue;
      }

      let node = document.createElement('div');
      let { title } = this.props;

      let search = queryString.stringify({
        title,
        image: image[0].src,
        path: window.location.pathname,
      });

      let url = `${window.location.origin}/twitter-redirect?${search}`;

      ReactDOM.render(
        <div className="entry-image-share">
          <TwitterShareButton title={title} kb url={url}>
            <SocialLink className="twitter" icon="twitter" />
          </TwitterShareButton>
        </div>,
        node,
      );

      // This handles the full width banner style entry images. They work better
      // with background-url and background-size cover
      if (entryImages[i].parentNode.className.indexOf('entry-images') > -1) {
        node.style.backgroundImage = `url(${image[0].src})`;
        node.setAttribute('title', image[0].alt || title);
        // If the image is not a banner image, don't mangle the markup
      } else {
        node.insertBefore(image[0].cloneNode(true), node.firstChild);
      }

      try {
        entryImages[i].replaceChild(node, image[0]);
      } catch (e) {
        console.log('Error:', e.message);
      }
    }
  }

  attachShareToPullquotes() {
    if (!this.articlePage) return;

    const entryPullquotes = this.articlePage.getElementsByClassName('pullquote');

    for (let i = 0; i < entryPullquotes.length; i++) {
      const node = document.createElement('div');
      const text = entryPullquotes[i].textContent;

      node.className = 'pullquote-share';

      if (text) {
        ReactDOM.render(
          <TwitterShareButton title={text} url={window.location.href}>
            <SocialLink className="twitter" icon="twitter" />

            <span>Tweet This</span>
          </TwitterShareButton>,
          node,
        );
      }

      entryPullquotes[i].appendChild(node);
    }
  }

  populateCardStacks() {
    if (typeof window === 'undefined') return;

    if (!this.articlePage) return;

    const cardStacks = Array.from(
      document.querySelectorAll('[data-js-card-stack]'),
    );

    for (let i = 0; i < cardStacks.length; i++) {
      const id = cardStacks[i].getAttribute('data-js-card-stack');
      const node = document.createElement('div');

      if (id) {
        // In order to render these card stack components into the inline html, we have to
        // create extra instances of the ApolloProvider. Otherwise, it won't be able to
        // fetch the data from the graphql server to feed into the card. It's not ideal,
        // but it's the most straightforward way I can think of to make this work.

        // Note that since we render the card's content into a Content component, we also
        // need to give it the BrowserRouter.
        ReactDOM.render(
          <ApolloProvider client={client}>
            <BrowserRouter>
              <CardStack id={id} />
            </BrowserRouter>
          </ApolloProvider>,
          node,
        );
      }

      this.articlePage.replaceChild(node, cardStacks[i]);
    }
  }

  onExternalLinkClick(e) {
    ReactGA.event({
      action: 'click',
      category: 'Outbound',
      label: `Navigated to ${e.currentTarget.href}`,
    });
  }

  wrapTablesForHorizontalScrolling() {
    if (!this.articlePage) return;

    const tables = this.articlePage.getElementsByTagName('table');

    for (let i = 0; i < tables.length; i++) {
      let node = document.createElement('div');
      node.className = 'table-container';
      node.appendChild(tables[i].cloneNode(true));

      tables[i].parentNode.replaceChild(node, tables[i]);
    }
  }

  render() {
    const { className, content = '', sanitizeContent = false } = this.props;
    const innerHTML = sanitizeContent ? sanitize(content) : { __html: content };

    return (
      <section
        id={this.id}
        className={classNames('Content', className)}
        dangerouslySetInnerHTML={innerHTML}
      />
    );
  }
}

Content.propTypes = {
  description: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string,
  sanitizeContent: PropTypes.bool,
  title: PropTypes.string,
};

Content.defaultProps = {};

export default withRouter(Content);
