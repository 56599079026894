import React from 'react';
import classNames from 'classnames';
import { NavLink, Route } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import AppHelmet from '../../AppHelmet';
import Culture from '../../../containers/Culture';
import Content from '../../Content';
import Divider from '../../Divider';
import Notice from '../Notice';
import SideNavList from '../../SideNavList';
import SideNavListContent from '../../SideNavListContent';

const CultureContent = ({ data, match }) => {
  return (
    <div className="CultureContent">
      <AppHelmet title="Culture" />

      <Row className="mb-4">
        <Col xs={12} lg={8} className="mb-3 mb-lg-0">
          <p>
          Consideration and non-discrimination are the cornerstones of Third Way’s Culture. Our employees
          are our most valuable assets. The creativity and collaboration of our employees enable us to produce
          our best work. We foster an environment of support, openness, and acceptance.
          </p>
        </Col>
        <Col xs={12} lg={4}>
          <Notice />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Divider className="mb-4 mb-lg-5" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4} className="pr-lg-5">
          <SideNavList className="mb-4">
            <ul className="list-unstyled">
              {data.map((c, index) => {
                const active =
                  RegExp('^/culture/?$').test(match.url) && index === 0;
                return (
                  <li key={c.id}>
                    <NavLink
                      to={{
                        pathname: `/culture/${c.slug}`,
                        state: { noScroll: true },
                      }}
                      className={classNames({ active })}
                    >
                      {c.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </SideNavList>
        </Col>
        <Col xs={12} lg={8}>
          <SideNavListContent>
            <Route
              exact
              path="/culture"
              component={() => {
                if (data.length > 0) {
                  return <Content content={data[0].cultureBody} />;
                }
                return <h1>No Culture content was found.</h1>;
              }}
            />
            <Route
              exact
              path="/culture/:slug"
              component={() => <Culture slug={match.params.slug} />}
            />
          </SideNavListContent>
        </Col>
      </Row>
    </div>
  );
};

export default CultureContent;
